import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";


// images
import banner_img from "../../images/icons/ta-emot.svg";
import e_faktura from '../../images/undersidor/e-faktura.svg';
import pdf_faktura from '../../images/undersidor/pdf_faktura.svg';
import skanning from '../../images/undersidor/skanner.svg';
import fakturadata from '../../images/undersidor/skanningsprogramvara.svg';

const BOT_CODE = "l8QZLG6U";

function Leverantorsfakturor() {
    const [chatbot_1, setChatbot_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Leverantörsfakturor | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Med CrossState eVoyce får du digital och effektiv fakturahantering" />
                <meta name="keywords" content="fakturahantering | CrossState eVoyce" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Med CrossState eVoyce får du digital och effektiv fakturahantering" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/leverantorsfakturor" />
                <link rel="cannonical" href="https://www.crediflow.se/leverantorsfakturor" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Digital och Effektiv fakturahantering</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Med CrossState eVoyce får du marknadens kanske mest 
                                        flexibla tjänst för att hantera dina fakturor. En 
                                        kanal in oavsett format, e-faktura, PDF och papper. 
                                        Ett självlärande system som bygger kunskap allt 
                                        eftersom du använder det med tolkning på huvud- 
                                        eller radnivå. Du väljer själv om du vill outsourca 
                                        hantering som scanning och verifiering 
                                        eller sköta det själv.
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">leverantörsfakturor</span>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div className="bg-white rounded-md shadow-md hover:shadow-xl transition-all transform hover:scale-105 lg:hover:-mt-2 lg:hover:mb-2">
                            <Link to="/leverantorsfakturor/e_faktura">
                                <div className="h-full flex flex-col justify-between">
                                    <div className="w-full px-6 pt-8 ">
                                        <div className="w-5/12 mx-auto">
                                            <img className="w-full" src={e_faktura} alt="ta emot e-fakturor"/>
                                        </div>
                                        <h2 className="mt-6 text-lg font-semibold text-center text-gray-900 hover:text-blue-custome">E-faktura</h2>
                                        <p className="mt-4 text-gray-500">
                                            Med vår tjänst tar du enkelt emot e-fakturor oavsett format. 
                                            Vi tar hand om det avancerade bakom så att du 
                                            kan fokusera på din verksamhet.
                                        </p>
                                    </div>
                                    <div className="pt-6 pb-8 flex justify-center">
                                        <button className="py-1 px-4 bg-gray-400 rounded-full text-white text-sm hover:bg-gray-500 shadow-md">Läs mer </button>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="bg-white rounded-md shadow-md hover:shadow-xl transition-all transform hover:scale-105 lg:hover:-mt-2 lg:hover:mb-2">
                            <Link to="/leverantorsfakturor/papper_pdf"  >
                                <div className="h-full flex flex-col justify-between">
                                    <div className="w-full px-6 pt-8 ">
                                        <div className="w-5/12 mx-auto">
                                            <img className="w-full" src={pdf_faktura} alt="automatisering av PDF-fakturor"/>
                                        </div>
                                        <h2 className="mt-6 text-lg font-semibold text-center text-gray-900 hover:text-blue-custome">PDF-fakturor</h2>
                                        <p className="mt-4 text-gray-500">
                                            Dina fakturor analyseras av AI teknologi som hittar 
                                            och extraherar data på huvud- eller radnivå. 
                                            Automatisk matchning av dina leverantörer och 
                                            avancerad kunskapsupplärning ger dig ökad 
                                            automatisering och säkerhet.
                                        </p>
                                    </div>
                                    <div className="pt-6 pb-8 flex justify-center">
                                        <button className="py-1 px-4 bg-gray-400 rounded-full text-white text-sm hover:bg-gray-500 shadow-md">Läs mer </button>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="bg-white rounded-md shadow-md hover:shadow-xl transition-all transform hover:scale-105 lg:hover:-mt-2 lg:hover:mb-2">
                            <Link to="/leverantorsfakturor/skanning">
                                <div className="h-full flex flex-col justify-between">
                                    <div className="w-full px-6 pt-8 ">
                                        <div className="w-5/12 mx-auto">
                                            <img className="w-full" src={skanning} alt="skanna pappersfakturor"/>
                                        </div>
                                        <h2 className="mt-6 text-lg font-semibold text-center text-gray-900 hover:text-blue-custome">Skanning av pappersfakturor</h2>
                                        <p className="mt-4 text-gray-500">
                                            Vill du ta emot och skanna dina pappersfakturor själv eller vill du få dem inskannade?
                                            <br/><br/>Med oss väljer du själv.
                                        </p>
                                    </div>
                                    <div className="pt-6 pb-8 flex justify-center">
                                        <button className="py-1 px-4 bg-gray-400 rounded-full text-white text-sm hover:bg-gray-500 shadow-md">Läs mer </button>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="bg-white rounded-md shadow-md hover:shadow-xl transition-all transform hover:scale-105 lg:hover:-mt-2 lg:hover:mb-2">
                            <Link to="/leverantorsfakturor/fakturakontroll">
                                <div className="h-full flex flex-col justify-between">
                                    <div className="w-full px-6 pt-8 ">
                                        <div className="w-5/12 mx-auto">
                                            <img className="w-full" src={fakturadata} alt="Kontroll av fakturadata"/>
                                        </div>
                                        <h2 className="mt-6 text-lg font-semibold text-center text-gray-900 hover:text-blue-custome">Kontroll av fakturadata</h2>
                                        <p className="mt-4 text-gray-500">
                                            Utläst information från en faktura ska alltid kontrolleras, så att det är verifierad och 
                                            korrekt data du sedan hanterar i ditt attest- eller affärssystem. 
                                            <br/><br/>Du väljer om du gör kontrollen själv eller om vi ska göra den åt dig?
                                        </p>
                                    </div>
                                    <div className="pt-6 pb-8 flex justify-center">
                                        <button className="py-1 px-4 bg-gray-400 rounded-full text-white text-sm hover:bg-gray-500 shadow-md">Läs mer </button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 pt-32 pb-28">
                <div className="container mx-auto px-4 xl:px-24">
                    <hr className="text-gray-200"/>
                </div>
            </section>




            <section className="bg-gray-100 pb-32">
                <div className="container mx-auto px-4 xl:px-24">
                    <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Fortfarande osäker på hur det fungerar?</h1>
                    <p className="mt-4 text-center text-gray-600">se vår film som förenklat beskriver hur vår lösning fungerar!</p>
                    <div className="w-full lg:w-2/3 mx-auto mt-14">
                        <iframe 
                            style={{minHeight: "450px"}}
                            className="w-full rounded-lg shadow-lg"
                            title="youtube-video-webinar"  
                            src="https://www.youtube-nocookie.com/embed/VkXN5k7s2e8"
                            frameBorder="0" 
                            allowFullScreen
                        />
                    </div>
                </div>
            </section>


        </Layout>
    );
}
export default Leverantorsfakturor;